import { useCallback } from 'react'
import { log } from './debug'
import { getTimestamp } from './getTimestamp'

export default function useTrackLinkClickEvent() {
  const trackLinkClickEvent = useCallback(
    ({ linkName, linkContent, linkDestination }) => {
      const dataLayerEntry = {
        event: 'linkClicked',
        linkName,
        linkContent,
        linkDestination,
        timestamp: getTimestamp()
      }

      window.adobeDataLayer.push(dataLayerEntry)
      log('useTrackLinkClickEvent', dataLayerEntry)
    },
    []
  )

  return trackLinkClickEvent
}
