import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { log } from './debug'
import { getTimestamp } from './getTimestamp'

export default function useTrackPageViewEvent({
  pageName,
  pageType,
  pageSubType,
  contentType,
  siteError,
  language,
  label
}) {
  const location = useLocation()

  useEffect(() => {
    const currentPath = location.pathname + location.search
    const siteSections = location.pathname.split('/').filter(Boolean)

    const dataLayerEntry = {
      event: 'pageView',
      value: 1,
      web: {
        webPageDetails: {
          pageName: pageName,
          pagePath: location.pathname,
          pageHost: window.location.host,
          pageTitle: document.title,
          pageURL: window.location.href,
          pageType: pageType,
          pageSubType: pageSubType,
          language: language,
          contentType: contentType,
          loggedInStatus: 'Unauthenticated',
          domain: window.location.hostname,
          siteError: siteError ? siteError : false,
          siteSection1: siteSections[0],
          siteSection2: siteSections[1],
          siteSection3: siteSections[2],
          siteSection4: siteSections[3],
          siteSection5: siteSections[4],
          siteSection6: siteSections[5]
        }
      },
      timestamp: getTimestamp()
    }

    if (currentPath !== window.previousPath) {
      window.adobeDataLayer.push(dataLayerEntry)
      log(`${label ? `${label} > ` : ''}useTrackPageViewEvent`, dataLayerEntry)
      window.previousPath = currentPath
    }
  }, [
    location,
    pageName,
    pageType,
    pageSubType,
    contentType,
    siteError,
    language,
    label
  ])
}
