const LOCAL_STORAGE_KEY = 'DebugAdobeAnalytics'

export function enableAnalyticsDebugging(prettyPrint = false) {
  const settings = { enabled: true, prettyPrint }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settings))
  const prettyPrintText = prettyPrint ? 'with pretty printed JSON' : ''
  console.log(`Adobe Analytics: debugging enabled ${prettyPrintText}`) // eslint-disable-line no-console
}

export function disableAnalyticsDebugging() {
  localStorage.removeItem(LOCAL_STORAGE_KEY)
  console.log('Adobe Analytics: debugging disabled') // eslint-disable-line no-console
}

export function log(label, value) {
  const settingsString = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (settingsString) {
    try {
      const settings = JSON.parse(settingsString)
      const prettyPrint = settings?.prettyPrint && typeof value === 'object'
      const valueToPrint = prettyPrint ? JSON.stringify(value, null, 2) : value
      console.log(`Adobe Analytics: ${label}:`, valueToPrint) // eslint-disable-line no-console
    } catch (error) {
      console.error('Adobe Analytics: Error parsing debug settings') // eslint-disable-line no-console
      localStorage.removeItem(LOCAL_STORAGE_KEY)
    }
  }
}
