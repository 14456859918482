import React, { Suspense, memo, useEffect } from 'react'
import Header from 'components/Header'
import styled from 'styled-components'
import ROUTES from 'config/routes'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Layout, LoadingSpin } from '@apple/ui-lib2'
import { enableAnalyticsDebugging, disableAnalyticsDebugging } from 'hooks/AdobeAnalytics'
import { adobeScriptName } from 'config/api'

import '@apple/ui-lib2/dist/theme/ui.css'
import './app.css'

function App() {
  useEffect(() => {
    window.adobeDataLayer = window.adobeDataLayer || []
    window.adobeEnableDebugging = enableAnalyticsDebugging
    window.adobeDisableDebugging = disableAnalyticsDebugging

    if (adobeScriptName) {
      const script = document.createElement('script')
      script.src = `https://assets.adobedtm.com/63f29da6144d/e2f8088542a4/${adobeScriptName}`
      script.async = true
      document.head.appendChild(script)

      return () => {
        document.head.removeChild(script)
      }
    }

    return () => {} // Ensure a consistent return
  }, [])

  return (
    <BrowserRouter>
      <StyledLayout>
        <Header />
        <Suspense fallback={<LoadingSpin />}>
          <Switch>
            {ROUTES.map(({ component: Component, ...route }, index) => (
              <Route key={index} exact={route.exact} path={route.path} component={Component} />
            ))}
            <Route path="*" render={() => <Redirect to={ROUTES[0].path} />} />
          </Switch>
        </Suspense>
      </StyledLayout>
    </BrowserRouter>
  )
}

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`

export default memo(App)
