const ENV = process.env.REACT_APP_BUILD_ENV || 'dev'

const DEV_API_HOST = `https://partnerhub-${ENV}.g.apple.com`

const adobeScriptName = {
  dev: 'launch-721504ce8766-development.min.js',
  uat: 'launch-721504ce8766-development.min.js',
  stage: 'launch-feb73f6113d9-staging.min.js',
  prod: 'launch-16837f45b9eb.min.js'
}[ENV]

module.exports = {
  routes: {
    requestHelp: `/sac/partnerhub-portal/request-help/**`
  },
  DEV_API_HOST,
  adobeScriptName
}
