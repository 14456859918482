export function getTimestamp() {
  const now = new Date()

  const utc = (() => {
    try {
      return now.toISOString()
    } catch {
      return null
    }
  })()

  const local = (() => {
    try {
      return new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString()
    } catch {
      return null
    }
  })()

  const timezone = (() => {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch {
      return null
    }
  })()

  const offset = (() => {
    try {
      const rawOffset = -now.getTimezoneOffset()
      return formatTimezoneOffset(rawOffset)
    } catch {
      return null
    }
  })()

  return {
    utc,
    local,
    timezone,
    offset
  }
}

function formatTimezoneOffset(offsetMinutes) {
  const sign = offsetMinutes >= 0 ? '+' : '-'
  const absOffset = Math.abs(offsetMinutes)
  const hours = String(Math.floor(absOffset / 60)).padStart(2, '0')
  const minutes = String(absOffset % 60).padStart(2, '0')
  return `UTC${sign}${hours}:${minutes}`
}
